<script>
    export default {
        props: {
            app: { required: true, type: Object },
            admin: { required: true, type: Object },
        },
    };
</script>
<template>
    <router-view :key="$route.fullPath"></router-view>
</template>
